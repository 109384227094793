//Dependencies
import "../styles/CardDetails/cardDetails.css";
import { PiArrowFatLeftFill } from "react-icons/pi";
import PropertyDetails from "./PropertyDetails";

function CardDetails({ goal, isActive, onClose }){

    if (!goal) {
        return null; // ou <> pour un fragment vide si vous ne voulez rien afficher
      }

    return (
        <div className={isActive ? "cardDetails active" : "cardDetails"}>
            <header>
                <div>
                    <span>But n°{goal.number}</span>
                    <span onClick={onClose}>
                        <PiArrowFatLeftFill size={25} color={"var(--detail-color)"}/>
                    </span>
                </div>
            </header>
            <div className="cardDetails__video">
            <video width="100%" height="auto" key={goal.number} controls>
                <source src={`https://cristiano-goals.s3.eu-west-3.amazonaws.com/CristianoRonaldoGoal${goal.number}.mp4`} type="video/mp4" />
            </video>
            <a href={`https://cristiano-goals.s3.eu-west-3.amazonaws.com/CristianoRonaldoGoal${goal.number}.mp4`}
                download={`CristianoRonaldoGoal${goal.number}.mp4`}
                rel="noreferrer"
                target="_blank">
                Télécharger ce but
            </a>
            </div>
            <h2 className="cardDetails__title">Détails</h2>
            <div className="cardDetails__details">
                <PropertyDetails property={"Équipe"} value={goal.team}/>
                <PropertyDetails property={"Adversaire"} value={goal.opponent}/>
                <PropertyDetails property={"Saison"} value={goal.season}/>
                <PropertyDetails property={"Date"} value={goal.date}/>
                <PropertyDetails property={"Compétition"} value={goal.competition}/>
                <PropertyDetails property={"Terrain"} value={goal.field}/>
                <PropertyDetails property={"Finition"} value={goal.finishing}/>
                <PropertyDetails property={"Passeur"} value={goal.assist}/>
                <PropertyDetails property={"Gardien adverse"} value={goal.goalkeeper}/>
                <PropertyDetails property={"Minute"} value={goal.minute}/>
            </div>
        </div>
    )
}

export default CardDetails;