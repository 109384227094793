// Dependencies
import "../styles/Card/card_style.css";
import { clubsLogo } from "../images/image-links";


function Card({ goal, onSelectGoal }) {
  return (
    <article className="card" onClick={onSelectGoal}>
      <div className="card__link">
        <header className="card__header">
          <div className="card__goal-number">
            <span>But n°</span>
            <span>{goal.number}</span>
          </div>
          <div className="card__opponent">
            <span>{goal.opponent}</span>
          </div>
          <div className="card__club">
            <img src={clubsLogo[goal.team]} alt={`Logo de ${goal.team}`} />
          </div>
        </header>
        <section className="card__stats first">
          <div className="card__stats__date">
            <span>{goal.date}</span>
          </div>
          <div className="card__stats__competition">
            <span>{goal.competition}</span>
          </div>
        </section>
        <section className="card__stats">
          <div className="card__stats__finishing">
            <span>{goal.finishing}</span>
          </div>
          <div className="card__stats__assist">
            <span>Passe: {goal.assist}</span>
          </div>
        </section>
      </div>
    </article>
  );
}

export default Card;