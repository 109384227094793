// Dependencies
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

export function useFetchGoals() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/goals`,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}
