import { useEffect, useState } from "react";
import Card from "./Card";

function CardList({ goals, onSelectGoal }) {
  // Initialiser displayedGoals à null pour indiquer que les données sont en cours de chargement
  const [displayedGoals, setDisplayedGoals] = useState(null);
  const [isReversed, setIsReversed] = useState(false); // Nouvel état pour gérer l'ordre des buts

  useEffect(() => {
    // Mettre à jour displayedGoals quand goals change
    setDisplayedGoals(goals);
  }, [goals]);

  const toggleOrder = () => {
    setIsReversed(!isReversed); // Basculer l'état isReversed
  };

  // Inverser l'ordre des buts si isReversed est vrai
  const goalsToDisplay = isReversed ? [...displayedGoals].reverse() : displayedGoals;

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  };

  const noContentStyle = {
    marginLeft: "20px",
    padding: "5px 0px",
    color: "var(--detail-color)"
  };

  // Gérer les trois états possibles : chargement, aucun but, et affichage des buts
  return (
    <>
      <div onClick={toggleOrder} className="card__change-direction">
        {isReversed ? "Afficher dans l'ordre croissant" : "Inverser l'ordre des buts"}
      </div>

      <div className="card__container" style={containerStyle}>
        {displayedGoals === null ? (
          // Afficher un message ou un spinner de chargement ici
          <div style={noContentStyle}>Chargement des buts...</div>
        ) : displayedGoals.length > 0 ? (
          goalsToDisplay.map((goal, index) => (
            <Card
              goal={goal}
              key={`goal-${index}`}
              onSelectGoal={() => onSelectGoal(goal)}
            />
          ))
        ) : (
          <div>
            <h2 style={noContentStyle}>Aucun but.</h2>
            <p style={noContentStyle}>
              Fais une autre recherche ou envoie-moi un message si tu penses que c'est une erreur.
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default CardList;
