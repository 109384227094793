// Dependencies
import React, { useState } from "react";
import axios from "axios"; // Assuming you're using axios for HTTP requests
import "../styles/AddNewGoal/addnewgoal_style.css";
import Header from "../components/Header";
import Button from "../components/Button";

const AddGoalForm = () => {
  const [formData, setFormData] = useState({
    number: "",
    season: "",
    team: "",
    date: "",
    opponent: "",
    field: "",
    competition: "",
    finishing: "",
    assist: "",
    minute: "",
    goalkeeper: "",
    code: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/goals/addnewgoal`,
        formData
      );
      const newGoal = response.data;
      console.log("New goal added:", newGoal);
      // Optionally, update state or perform any additional actions
    } catch (error) {
      console.error("Error adding goal:", error);
      // Handle error: display error message to user, etc.
    }
  };

  const fields = [
    "number",
    "team",
    "opponent",
    "season",
    "date",
    "competition",
    "field",
    "finishing",
    "assist",
    "goalkeeper",
    "minute",
  ];

  const fieldTranslations = {
    number: "But n°",
    team: "Équipe",
    opponent: "Adversaire",
    season: "Saison",
    date: "Date",
    competition: "Compétition",
    field: "Terrain",
    finishing: "Finition",
    assist: "Passe décisive",
    goalkeeper: "Gardien de but",
    minute: "Minute",
  };

  return (
    <>
      <Header />
      <form onSubmit={handleFormSubmit} className="addnewgoal">
        {fields.map((field) => (
          <div className="addnewgoal__property-input" key={field}>
            <label htmlFor={field}>{fieldTranslations[field]}</label>
            <input
              type="text"
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              required
            />
          </div>
        ))}
      </form>
      <div className="addnewgoal__container-submit">
        <Button
          value="Ajouter un nouveau but"
          display="flex-start"
          fill={true}
          margin={"50px 0px 15px 20px"}
          onClick={handleFormSubmit}
        />
      </div>
      <div className="addnewgoal__code" key="code">
        <label htmlFor="code">Code</label>
        <input
          type="password"
          id="code"
          name="code"
          value={formData.code}
          onChange={handleChange}
          required
        />
      </div>
    </>
  );
};

export default AddGoalForm;
