export const clubsLogo = {
  Portugal:
    "https://logodownload.org/wp-content/uploads/2021/10/fpf-selecao-de-portugal-logo.png",
  "Real Madrid":
    "https://upload.wikimedia.org/wikipedia/fr/thumb/c/c7/Logo_Real_Madrid.svg/1468px-Logo_Real_Madrid.svg.png",
  "Manchester United":
    "https://upload.wikimedia.org/wikipedia/en/thumb/7/7a/Manchester_United_FC_crest.svg/1200px-Manchester_United_FC_crest.svg.png",
  "Sporting CP":
    "https://upload.wikimedia.org/wikipedia/fr/thumb/1/12/Logo_Sporting_Clube_Portugal.svg/1200px-Logo_Sporting_Clube_Portugal.svg.png",
  Juventus:
    "https://companieslogo.com/img/orig/JUVE.MI.D-041d1d80.png?t=1659935888",
  "Al-Nassr":
    "https://upload.wikimedia.org/wikipedia/fr/archive/9/9d/20231206044853%21Logo_Al-Nassr.png",
};

export const propertyDetailsImg = {
  Passeur: [
    {
      label: "Karim Benzema",
      img: "https://i.goalzz.com/?i=o%2Fp%2F21%2F490%2Fkarim-benzema-1.png",
    },
    {
      label: "Bernardo Silva",
      img: "https://sortitoutsidospaces.b-cdn.net/megapacks/cutoutfaces/originals/12.00/55041632.png",
    },
  ],
  Équipe: [
    {
      label: "Sporting CP",
      img: "https://upload.wikimedia.org/wikipedia/fr/thumb/1/12/Logo_Sporting_Clube_Portugal.svg/1200px-Logo_Sporting_Clube_Portugal.svg.png",
    },
    {
      label: "Portugal",
      img: "https://logodownload.org/wp-content/uploads/2021/10/fpf-selecao-de-portugal-logo.png",
    },
    {
      label: "Real Madrid",
      img: "https://upload.wikimedia.org/wikipedia/fr/thumb/c/c7/Logo_Real_Madrid.svg/1468px-Logo_Real_Madrid.svg.png",
    },
    {
      label: "Manchester United",
      img: "https://upload.wikimedia.org/wikipedia/en/thumb/7/7a/Manchester_United_FC_crest.svg/1200px-Manchester_United_FC_crest.svg.png",
    },
    {
      label: "Juventus",
      img: "https://companieslogo.com/img/orig/JUVE.MI.D-041d1d80.png?t=1659935888",
    },
  ],
  Compétition: [
    {
      label: "Ligue des Champions",
      img: "https://imagecache.365scores.com/image/upload/f_png,w_250,h_250,c_limit,q_auto:eco,d_Countries:Round:19.png/v5/Competitions/332",
    },
  ],
};
