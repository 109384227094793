// Dependencies
import "../styles/Button/button_style.css"

function Button({value, display, width, fill, onClick, margin, background}) {

    const divButtonStyle = {
        justifyContent: display
    }

    const buttonStyle = {
        color: fill ? "var(--main-color)" : "var(--secondary-color)",
        width: "225px",
        background: background ? background : "var(--detail-color)",
        border: fill ? "none" : "2px solid var(--detail-color",
        fontWeight: fill ? "800" : "600",
        margin: margin ? margin : "0px",
    }

    return (
    <div className="button" style={divButtonStyle}>
        <button style={buttonStyle} onClick={onClick}>{value}</button>
    </div>
    )
}

export default Button;