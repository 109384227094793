// SearchGoal.jsx functions
export const handleCheckboxChange = (
  checkboxStates,
  setCheckboxStates,
  property,
  value
) => {
  setCheckboxStates((prevStates) => ({
    ...prevStates,
    [property]: {
      ...prevStates[property],
      [value]: !prevStates[property][value],
    },
  }));
};

// Extract unique values for a property
export const getUniqueValues = (data, key) => {
  return [...new Set(data.map((item) => item[key]))];
};

export const renderCheckboxes = (
  checkboxStates,
  selectedProperty,
  handleCheckboxChange,
  setCheckboxStates,
  searchTerm
) => {
  const currentPropertyStates = checkboxStates[selectedProperty] || {};

  // Fonction pour vérifier si une chaîne est une date au format jj/mm/aaaa
  const isDate = (str) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(str);
  };

  // Fonction pour convertir une chaîne de date en objet Date
  const parseDate = (str) => {
    const [day, month, year] = str.split("/").map(Number);
    return new Date(year, month - 1, day); // Mois en base 0
  };

  // Filtrer les clés basées sur searchTerm
  const filteredKeys = Object.keys(currentPropertyStates).filter((value) =>
    value.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Trier les clés en tenant compte des valeurs numériques, textuelles et des dates
  const sortedKeys = filteredKeys.sort((a, b) => {
    const aIsDate = isDate(a);
    const bIsDate = isDate(b);

    // Convertir les valeurs en dates si elles sont au format jj/mm/aaaa
    if (aIsDate && bIsDate) {
      return parseDate(a) - parseDate(b);
    } else if (aIsDate) {
      // Les dates viennent avant les non-dates
      return -1;
    } else if (bIsDate) {
      // Les dates viennent avant les non-dates
      return 1;
    } else {
      // Vérifier si les valeurs sont numériques
      const isANumeric = !isNaN(Number(a)) && !isNaN(parseFloat(a));
      const isBNumeric = !isNaN(Number(b)) && !isNaN(parseFloat(b));

      if (isANumeric && isBNumeric) {
        // Comparer numériquement si les deux sont numériques
        return Number(a) - Number(b);
      } else if (isANumeric) {
        // Les numériques viennent avant les non-numériques
        return -1;
      } else if (isBNumeric) {
        // Les numériques viennent avant les non-numériques
        return 1;
      } else {
        // Comparer alphabétiquement si les deux sont non-numériques
        return a.localeCompare(b);
      }
    }
  });

  return sortedKeys.map((value) => (
    <div key={value} className="searchGoal__filter__checkbox">
      <input
        type="checkbox"
        id={value}
        checked={currentPropertyStates[value]}
        onChange={() =>
          handleCheckboxChange(
            checkboxStates,
            setCheckboxStates,
            selectedProperty,
            value
          )
        }
      />
      <label htmlFor={value}>{value}</label>
    </div>
  ));
};

export const handleSearchClick = async (checkboxStates, onFilterApplied) => {
  const checkedValues = Object.keys(checkboxStates).reduce((acc, property) => {
    acc[property] = Object.keys(checkboxStates[property]).filter(
      (key) => checkboxStates[property][key]
    );
    return acc;
  }, {});

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/goals/filter`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(checkedValues),
      }
    );

    if (response.status === 400) {
      return false;
    }

    const filteredGoals = await response.json();
    onFilterApplied(filteredGoals);
    return true;
  } catch (error) {
    return null;
  }
};

export const propertyLabels = {
  team: "Équipe",
  opponent: "Adversaire",
  season: "Saison",
  competition: "Compétition",
  date: "Date",
  finishing: "Finition",
  field: "Terrain",
  minute: "Minute",
  assist: "Passeur",
  goalkeeper: "Gardien",
  number: "But n°",
};

export const selectAll = (
  checkboxStates,
  setCheckboxStates,
  selectedProperty
) => {
  setCheckboxStates((prevStates) => ({
    ...prevStates,
    [selectedProperty]: Object.keys(prevStates[selectedProperty]).reduce(
      (acc, key) => {
        acc[key] = true; // Set each value to true
        return acc;
      },
      {}
    ),
  }));
};

export const deselectAll = (
  checkboxStates,
  setCheckboxStates,
  selectedProperty
) => {
  setCheckboxStates((prevStates) => ({
    ...prevStates,
    [selectedProperty]: Object.keys(prevStates[selectedProperty]).reduce(
      (acc, key) => {
        acc[key] = false; // Set each value to false
        return acc;
      },
      {}
    ),
  }));
};

export const resetAllCheckbox = (checkboxStates, setCheckboxStates) => {
  const resetStates = {};
  Object.keys(checkboxStates).forEach((property) => {
    const currentPropertyStates = checkboxStates[property];
    Object.keys(currentPropertyStates).forEach((key) => {
      if (!resetStates[property]) {
        resetStates[property] = {};
      }
      resetStates[property][key] = true; // Réinitialise chaque checkbox à true
    });
  });
  setCheckboxStates(resetStates);
};
