// Dependencies
import { useFetchGoals } from "../hooks/fetchGoal";

// Components
import Header from "../components/Header";
import CardList from "../components/CardList";
import SearchGoal from "../components/SearchGoal";
import { useState } from "react";
import GoalsCounter from "../components/GoalsCounter";
import CardDetails from "../components/CardDetails";
// import { useEffect, useState } from "react";

function Home() {
  const [filteredGoals, setFilteredGoals] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);

  const handleSelectGoal = (goal) => {
    setSelectedGoal(goal);
  };

  // Dans le composant Home
  const handleCloseCardDetails = () => {
    setSelectedGoal(null);
  };

  // Fetch all goals
  const {
    data: allGoals,
    isLoading: isLoadingAllGoals,
    isError: isErrorAllGoals,
  } = useFetchGoals();

  if (isErrorAllGoals) return <div>Une erreur est survenue</div>;
  if (isLoadingAllGoals)
    return <div className="global-loading">Chargement...</div>;

  const totalGoals = allGoals.length;
  const numberOfGoals = filteredGoals ? filteredGoals.length : totalGoals;

  return (
    <>
      <Header />
      <GoalsCounter numberOfGoals={numberOfGoals} totalGoals={totalGoals} />
      <SearchGoal onFilterApplied={setFilteredGoals} />
      <CardList
        goals={filteredGoals || allGoals}
        onSelectGoal={handleSelectGoal}
      />
      <CardDetails
        goal={selectedGoal}
        isActive={!!selectedGoal}
        onClose={handleCloseCardDetails}
      />
    </>
  );
}

export default Home;
