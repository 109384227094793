// Dependencies
import "../styles/PropertyDetails/propertyDetails_style.css"
// import { propertyDetailsImg } from "../images/image-links";

function PropertyDetails({property, value}) {

    /* function findImageByProperty(property, value) {
        const category = propertyDetailsImg[property];
      
        // Vérifie que la catégorie n'est pas undefined avant de continuer
        if (!category) {
          return 'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'; // Fournit une URL d'image par défaut si la catégorie n'est pas trouvée
        }
      
        const item = category.find(item => item.label === value);
        return item ? item.img : 'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg';
      } */
      

      // const imageUrl = findImageByProperty(property, value);

    return (
        <article className="propertyDetails">
            <div className="propertyDetails__prop-value">
                <span>{property}</span>
                <span>{value}</span>
            </div>
        </article>
    )
}

export default PropertyDetails;

/*
            <div className="propertyDetails__img">
                <img src={imageUrl} alt={value} />
            </div>
*/