// Dependencies
import "../styles/SearchGoal/searchGoal_style.css"
import { useFetchGoals } from "../hooks/fetchGoal";
import { useEffect, useState } from "react";
import {
    handleCheckboxChange,
    renderCheckboxes,
    propertyLabels,
    getUniqueValues,
    handleSearchClick,
    selectAll,
    deselectAll,
    resetAllCheckbox
  } from '../functions/searchGoal_function';
import Button from "./Button";
import { MdOutlinePlaylistAddCheck, MdPlaylistRemove } from "react-icons/md";
import { PiArrowFatLeftFill } from "react-icons/pi";

import { toast } from 'react-toastify';



function SearchGoal({ onFilterApplied }) {

    // State for selected property // "team" by default
    const [selectedProperty, setSelectedProperty] = useState("team");
    const [checkboxStates, setCheckboxStates] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [isTreatment, setIsTreatment] = useState(false);

    const notify = (value, time) => toast(value,{
        autoClose:time,
        pauseOnHover: false,
    });

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
        if (!isFilterVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'scroll';
  }
      };

    const onSearchClick = async () => {
      handleSearchClick(checkboxStates, onFilterApplied);
      const success = await handleSearchClick(checkboxStates, onFilterApplied);

      if(success) {
        setIsTreatment(true);
        notify("Traitement en cours.", 400)
        setTimeout(() => {
          toggleFilterVisibility();
          notify("Recherche effectuée avec succès.", 1500)
        }, 850)
  
        setTimeout(() => {
          setIsTreatment(false);
        }, 1000)
      } else {
        toast.error("Erreur. Tu as déselectionné toute une catégorie.")
      }
    };

    // To reset input search when selectedProperty change
    useEffect(() => {
        setSearchTerm("");
    }, [selectedProperty]);

    // Fetch all goals
    const { data: allGoals, isLoading: isLoadingAllGoals, isError: isErrorAllGoals } = useFetchGoals();
    if (isErrorAllGoals) return <div>Une erreur est survenue</div>;
    if (isLoadingAllGoals) return <div>Chargement...</div>;

    if (allGoals && Object.keys(checkboxStates).length === 0) {
        const newCheckboxStates = {};
        ["team", "opponent", "date", "minute", "season", "finishing", "goalkeeper", "field", "assist", "competition", "number"].forEach(property => {
            newCheckboxStates[property] = getUniqueValues(allGoals, property).reduce((acc, value) => {
                acc[value] = true;
                return acc;
            }, {});
        });
        setCheckboxStates(newCheckboxStates);
    }

      // Utilisez selectAll et deselectAll au besoin
  const handleSelectAllClick = () => {
    selectAll(checkboxStates, setCheckboxStates, selectedProperty);
  };

  const handleDeselectAllClick = () => {
    deselectAll(checkboxStates, setCheckboxStates, selectedProperty);
  };

  const handleResetClick = () => {
    resetAllCheckbox(checkboxStates, setCheckboxStates);
  };

  /* const openStats = () => {
    const divStats = document.querySelector(".stats")
    console.log(divStats)
    divStats.classList.add("visible");
  } */

    return (
    <section className="searchGoal">
        <Button value="Rechercher un but" display="flex-start" fill={true} margin={"50px 0px 15px 20px"} onClick={toggleFilterVisibility}/>
        <div className={`searchGoal__filter-background ${isFilterVisible ? 'active' : ''}`} onClick={toggleFilterVisibility}></div>
        <div className={`searchGoal__filter ${isFilterVisible ? 'active' : ''}`}>
            <header className="searchGoal__filter__header">
                <h2>Rechercher un but</h2>
                <PiArrowFatLeftFill size={25} onClick={toggleFilterVisibility}/>
            </header>
            <div className="searchGoal__filter__property">
                {Object.entries(propertyLabels).map(([key, label]) => (
                    <span key={key} onClick={() => setSelectedProperty(key)}
                    className={selectedProperty === key ? "searchGoal__filter__span-selected": "searchGoal__filter__span"}>
                        {label}
                    </span>
                ))}
            </div>
            <h2 className="searchGoal__filter__selected-property">{propertyLabels[selectedProperty]}</h2>
            <div className="searchGoal__filter__input-search">
                <input type="search"
                placeholder="Taper pour filtrer"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}></input>
            </div>
            <div className="searchGoal__filter__select-deselect">
                <span onClick={handleSelectAllClick}><MdOutlinePlaylistAddCheck size="25"/>Tout sélectionner</span>
                <span onClick={handleDeselectAllClick}><MdPlaylistRemove size="25"onClick={handleDeselectAllClick}/>Tout déselectionner</span>
            </div>
            <div className="searchGoal__filter__list">
            {renderCheckboxes(checkboxStates, selectedProperty, handleCheckboxChange, setCheckboxStates, searchTerm)}
            </div>
            <Button value={isTreatment ? "Traitement..." : "Rechercher"} display="center" fill={true} onClick={onSearchClick}/>
            <Button value="Réinitialiser" display="center" fill={false} margin={"25px 0px 75px 0px"} background={"transparent"} onClick={handleResetClick}/>
        </div>
    </section>
    )
}

export default SearchGoal;