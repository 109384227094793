// Dependencies
import { useEffect, useState } from "react";
import "../styles/GoalsCounter/goalsCounter.css";
import { PiArrowFatLineUpFill } from "react-icons/pi";

function GoalsCounter({numberOfGoals, totalGoals}) {
    
    const [isVisible, setIsVisible] = useState(false);
    const goalsPercentage = ((numberOfGoals * 100) / totalGoals).toFixed(1);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        // Fonction pour gérer le défilement
        const handleScroll = () => {
          if (window.scrollY > 200) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        };
    
        // Ajouter l'événement de défilement
        window.addEventListener("scroll", handleScroll);
    
        // Nettoyer l'événement lors du démontage du composant
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return (
        <>
            <section className="goalsCounter">
                {numberOfGoals > 0 ? (
                    <>
                        <h2>{numberOfGoals} buts affichés</h2>
                        <p>Soit {goalsPercentage}% des buts de Cristiano Ronaldo</p>
                    </>
                ) : (
                    <>
                        <h2>0 but affiché.</h2>
                        <p>Du coup ça fait 0% des buts de Cristiano Ronaldo, grâce à un grand calcul.</p>
                    </>
                )}
            </section>
                {isVisible && (
                    <div className="goalsCounter__hide" onClick={scrollToTop}>
                        <PiArrowFatLineUpFill size={50}/>
                    </div>
                )}
        </>
    )
}

export default GoalsCounter;