// Dependencies
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddNewGoal from "./pages/AddNewGoal";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/addnewgoal" element={<AddNewGoal />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer progressClassName="notification" />
    </>
  );
}

export default App;
