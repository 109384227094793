// Dependencies
import { Link } from "react-router-dom";
import "../styles/Header/header_style.css"

function Header() {
    return <header className="header">
        <div>Cristianostats.com</div>
        <span className="header__link"><Link to="https://x.com/arobasegiovanny">par ArobaseGiovanny</Link></span>
        </header>
}

export default Header;